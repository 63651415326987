import React, { useState, useCallback } from "react"
import block from "bem-cn"
import Content from "../../components/Content"
import "./style.scss"
import { sendYMEvent } from "../../helpers"

const b = block("questions")

const questionTitles = [
  {
    title: "На какие события продаются билеты?",
    analyticsText: "na_kakie_meropriyatiya_mozhno_kupit_bilety",
    description:
      "В нашей афише десятки тысяч событий: концерты, спектакли, выставки и спортивные игры.",
  },
  {
    title: "Как начисляется кешбэк? На что его потратить?",
    analyticsText: "kak_nachislyaetsya_keshbek_na_chto_ego_mozhno_potratit",
    description:
      "Авторизуйтесь в приложении и купите билет. Сумма кешбэка будет указана в карточке события. Он поступит на ваш счёт в МТС Cashback в течение суток после начала события. Потратить кешбэк можно на оплату сервисов МТС или на покупки в магазинах наших партнёров.",
  },
  {
    title: "Что такое привилегии?",
    analyticsText: "chto_takoe_privilegii",
    description:
      "На отдельных мероприятиях пользователям приложения МТС Live доступны привилегии: отдельный вход в зрительный зал, доступ в VIP и лаунж-зоны, комплименты в баре и т.д. Привилегии действуют не на всех событиях.",
  },
  {
    title: "Что такое digital-контент?",
    analyticsText: "chto_takoe_digital-kontent",
    description:
      "В разделе «Digital-контент» доступны бесплатные AR-спектакли и аудиогиды по городам и выставкам. Загрузите их заранее на телефон, чтобы использовать даже без интернета.",
  },
  {
    title: "Что такое AR? Как это работает?",
    analyticsText: "chto_takoe_ar_ak_eto_rabotaet",
    description:
      "AR — технология дополненной реальности. На некоторых выставках и концертах доступен AR-контент. Чтобы открыть AR-объект, используйте камеру телефона и следуйте инструкциям в приложении.",
  },
  {
    title: "Как заказывать еду и напитки в приложении?",
    analyticsText: "kak_zakazyvat_edu_i_napitki_v_prilozhenii",
    description:
      "Узнайте у сотрудников код площадки. Введите его в разделе «Актуальное». Откройте сервис «Напитки и еда» в карточке площадки. Выберите блюда и оплатите заказ онлайн. Его принесут за ваш столик или на точку выдачи, когда всё будет готово.",
  },
]

function QuestionItem({ item }) {
  const [isOpened, setIsOpened] = useState(false)
  const itemOnClick = useCallback(() => {
    if (!isOpened) {
      sendYMEvent({
        eventAction: "button_click",
        eventLabel: "prosmotr_voprosa",
        eventContent: item.analyticsText,
      })
    }
    setIsOpened(!isOpened)
  }, [isOpened])

  return (
    <div className={b("item")}>
      <div className={b("title-block")} onClick={itemOnClick}>
        <span className={b("item-title")}>{item.title}</span>
        <div className={b("item-icon", { isOpened })} />
      </div>
      <span className={b("description", { isOpened })}>{item.description}</span>
    </div>
  )
}

export default function Questions() {
  return (
    <div className={b()}>
      <div className={b("container")}>
        <Content>
          <span className={b("title")}>Частые вопросы</span>
          <div className={b("list")}>
            {questionTitles.map((t, i) => (
              <QuestionItem key={i} item={t} />
            ))}
          </div>
        </Content>
      </div>
    </div>
  )
}
