import React from "react"
import block from "bem-cn"
import Content from "../../components/Content"

import "./style.scss"
const b = block("about-app")

const cards = [
  {
    title: "Каталог развлечений",
    text: "Концерты, спектакли, выставки и шоу",
    image: "catalog",
  },
  {
    title: "Кешбэк до 20%",
    text: "Часть потраченных денег  вернётся к вам",
    image: "cashback",
  },
  {
    title: "Удобный доступ к билетам",
    text: "Всё хранится в одном приложении",
    image: "access",
  },
  {
    title: "Сервисы",
    text: "Схема зала, расписание, фотозона и заказ в баре",
    image: "services",
  },
  {
    title: "Digital-контент",
    text: "Аудиогиды, AR-спектакли и онлайн-экскурсии ",
    image: "content",
  },
]

export default function AboutApp() {
  return (
    <div className={b()}>
      <div className={b("text-block")}>
        <div className={b("content")}>
          <Content>
            <span className={b("text-block-title")}>О ПРИЛОЖЕНИИ</span>
            <span className={b("qr-mobile-text")}>
            МТС Live — тысячи увлекательных событий вашего города в одном месте. Публикуем новости, статьи и подборки каждую неделю, чтобы вам было легче выбрать отдых по вкусу.
            </span>
            <div className={b("cards")}>
              {cards.map(t => (
                <div key={t.title} className={b("card", { [t.image]: true })} />
              ))}
            </div>
            <div className={b("container")}>
              <div className={b("cicles")}></div>
            </div>
          </Content>
        </div>
      </div>
    </div>
  )
}
